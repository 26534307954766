.ArticleSummary h2 {
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;
}

.ArticleSummary a {
  text-decoration: none;
}

.ArticleSummary {
  display: flex;
  padding: 20px 30px;
  border-radius: 10px;
  transition: 0.3s all;
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .ArticleSummary {
    background: rgb(250, 250, 250);
    display: inline;
    padding: 20px 30px;
    border-radius: 10px;
  }
}

.ArticleImage {
  width: 200px;
  border-radius: 5px;
  margin-left: 20px;
  height: 200px;
}
